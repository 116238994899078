import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#F56430",
        secondary: "#206D5F",
        error: "#FF5252",
        info: "#20316D",
        purple: "#6D205C",
        success: "#206D5F",
        dark: "#000000",
        indigo: "#D1154B",
        "primary--light": "rgba(247, 81, 22, 0.1)",
        "dark--light": "rgba(0, 0, 0, 0.1)",
      },
      light: {
        primary: "#F56430",
        secondary: "#206D5F",
        error: "#FF5252",
        info: "#20316D",
        purple: "#6D205C",
        success: "#206D5F",
        dark: "#000000",
        indigo: "#D1154B",
        "primary--light": "rgba(247, 81, 22, 0.1)",
        "dark--light": "rgba(0, 0, 0, 0.1)",
      },
    },
  },
});
