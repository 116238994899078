import { PaymentService } from "@/services";

const actions = {

    // ========= Verify Payment ==========  
    async verifyPayments(context,payload) {
        const data = await PaymentService.verifyPayment(payload);
        return data;
    },
}

export default {
    namespaced: true,
    actions,
} 