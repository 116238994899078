import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class SupersaveService {
  // Get Super Save
  static async getSupersave() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.SUPERSAVE);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }
}