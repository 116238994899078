import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    snackbar: {
      timeout: 5000,
      text: "",
      isActive: false,
      props: {},
    },
  },
  getters: {
    snackbar: ({ snackbar }) => snackbar,
  },
  mutations: {
    TOGGLE_SNACK(state, { show, text, props = {}, timeout = 5000 }) {
      state.snackbar = {
        ...state.snackbar,
        text,
        isActive: show,
        timeout,
        props: { ...props },
      };
    },
  },
  actions: {
    openSnackbar({ commit }, { text, props = {} }) {
      commit("TOGGLE_SNACK", { show: true, text, props });
      setTimeout(() => {
        commit("TOGGLE_SNACK", { show: false, text: "" });
      }, 5000);
    },
    closeSnackbar({ commit }) {
      commit("TOGGLE_SNACK", { show: false, text: "" });
    },
  },
  modules: {},
};
