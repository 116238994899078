import * as ROUTES from "../routes";
const routes = [
  {
    path: ROUTES.PROFILE,
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/profile/views"),
    meta: {
      title: "Profile",
      layout: "private",
      requiresAuth: true,
    },
    children: [
      {
        path: ROUTES.BANKACCOUNT,
        name: "Bank Account",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/profile/views/bank"),
        meta: {
          title: "Bank Account",
          layout: "private",
          requiresAuth: true,
        },

        children: [
          {
            path: "verify-bank",
            name: "Verify-Bank",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/profile/components/VerifyBank"
              ),
            meta: {
              title: "Bank Account",
              layout: "private",
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "",
        name: "My Profile",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/profile/views/edit"),
        meta: {
          title: "My Profile",
          layout: "private",
          requiresAuth: true,
        },
      },
      {
        path: ROUTES.CHANGEPIN,
        name: "Change Pin",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/profile/views/pin"),
        meta: {
          title: "Change Pin",
          layout: "private",
          requiresAuth: true,
        },
      },
      {
        path: ROUTES.KYC,
        name: "KYC",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/profile/views/kyc"),
        meta: {
          title: "KYC",
          layout: "private",
          requiresAuth: true,
        },
      },

      {
        path: ROUTES.ABOUT,
        name: "About",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/profile/views/about"),
        meta: {
          title: "About",
          layout: "private",
          requiresAuth: true,
        },
      },

      {
        path: "support",
        name: "Support",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/profile/views/support"
          ),
        meta: {
          title: "Support",
          layout: "private",
          requiresAuth: true,
        },
      },
      {
        path: "cards",
        name: "My Cards",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/profile/views/cards"),
        meta: {
          title: "My Cards",
          layout: "private",
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
