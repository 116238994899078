import * as ROUTES from "../routes";

const routes = [
  {
    path: ROUTES.LOGIN,
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/auth/views/login"),
    meta: {
      title: "Login",
      layout: "public",
    },
  },
  {
    path: ROUTES.REGISTER,
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/auth/views/register"),
    meta: {
      title: "Login",
      layout: "public",
    },
  },
  {
    path: ROUTES.FORGOT,
    name: "Forgot",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/auth/views/forgot"),
    meta: {
      title: "FORGOT PIN",
      layout: "public",
    },
  },
  {
    path: ROUTES.RESET,
    name: "Reset PIN",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/auth/views/reset"),
    meta: {
      title: "RESET PIN",
      layout: "public",
    },
  },
  {
    path: ROUTES.CHANGE_NUMBER,
    name: "Change Number",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/auth/views/changeNumber"),
    meta: {
      title: "Change Number",
      layout: "public",
    },
  },
  {
    path: ROUTES.VERIFY,
    name: "Verify Account",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/auth/views/verify"),
    meta: {
      title: "Verify Accounr",
      layout: "public",
    },
  },
];
export default routes;
