import { PowersaveService } from "@/services";
import app from "@/main";

const state = {
  initiatingPowersave: null,
  initiatingWithdrawal: null,
};

const mutations = {
  initiatePowersave(state, payload) {
    state.initiatingPowersave = payload;
  },
  initiateWithdrawal(state, payload) {
    state.initiatingWithdrawal = payload;
  },
};

const getters = {
  powersaveInitializers: (state) => state.initiatingPowersave,
  withdrawalInitializers: (state) => state.initiatingWithdrawal,
};

const actions = {
  async getPowersave({ commit }) {
    const data = await PowersaveService.getPowersave();
    await commit("setPowersave", data.payload, {
      root: true,
    });
  },

  async startPowersave(context, details) {
    const data = await PowersaveService.startPowersave(details);
    return data;
  },

  async saveInitiatePowersave({ commit }, payload) {
    commit("initiatePowersave", payload);
  },

  async getFrequency() {
    const data = await PowersaveService.getFrequency();
    return data.payload;
  },

  async saveInitiateWithdrawal({ commit }, payload) {
    commit("initiateWithdrawal", payload);
  },

  async pausePowersave(context, details) {
    const data = await PowersaveService.pausePowersave(details);
    await context.dispatch("getPowersave");
    app.$store.dispatch("snackbar/openSnackbar", {
      text: "Power Save subscription has been Paused",
    });
    return data;
  },

  async restartPowersave(context, details) {
    const data = await PowersaveService.restartPowersave(details);
    await context.dispatch("getPowersave");
    app.$store.dispatch("snackbar/openSnackbar", {
      text: "Power Save subscription has been activated",
    });
    return data;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};
