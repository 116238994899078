import app from "@/main";
import { AuthService, BankService, UserService } from "../../services";

const state = {
  bank: null,
  securityQuestions: null,
  userQuestion: null,
  notifications: null,
};

const mutations = {
  saveBank(state, payload) {
    state.bank = payload;
  },
  saveQuestions(state, payload) {
    state.securityQuestions = payload;
  },
  saveUserQuestion(state, payload) {
    state.userQuestion = payload;
  },
  saveNotifications(state, payload) {
    state.notifications = payload;
  },
};

const getters = {
  getSuretyNotification(state) {
    if (state.notifications && state.notifications.length > 0) {
      let message = state.notifications.find(
        (elm) => elm.type == "surety" && elm.is_read == false
      );
      if (message) {
        return message;
      }
    }

    return null;
  },
};

const actions = {
  // ===== Fetch User Profile ========//
  async fetchUser({ commit }) {
    let { data } = await AuthService.fetchUserProfile();
    await commit("storeUser", data.payload, { root: true });
    localStorage.setItem('bank', data.payload.customer.bank_acccount_added)
    return data;
  },

  // ======== Update User Profile ===========//
  async updateProfile(context, payload) {
    let { data } = await AuthService.updateProfile(payload);
    app.$store.dispatch("user/fetchUser");

    app.$store.dispatch("snackbar/openSnackbar", {
      text: "Profile updated successfully",
    });
    return data;
  },

  // ========== Fetch user's bank account  ========//
  async fetchBankAcct({ commit }) {
    let { payload } = await BankService.fetchBankAcct();
    await commit("saveBank", payload.rows[0]);
    return payload;
  },

  // ========== Fetch Lists Of Banks =========== //
  async fetchBankLists() {
    let { payload } = await BankService.fetchBankLists();
    return payload;
  },

  // ============ Resolve Account Name ============//
  async resolveAccountName(context, payload) {
    let data = await BankService.resolveAccountName(payload);
    return data;
  },

  //================== FETCH SAVINGS PURPOSE ===============//
  async getSavingsPurpose() {
    const { payload } = await UserService.fetchSavingsPurpose();
    return payload;
  },

  //================== FETCH SECURITY QUESTION ===============//
  async getSecurityQuestion({ commit }) {
    let data = await UserService.fetchSecurityQuestion();
    commit("saveQuestions", data.payload);
    return data;
  },

  // ============ Save Security Questionn =============//
  async saveSecurityQuestionRequest(context, payload) {
    let data = await UserService.saveSecurityQuestion(payload);

    app.$store.dispatch("user/fetchUser");
    return data;
  },

  //================ Add Bank Account =======//
  async addBankAccount(context, payload) {
    let { data } = await BankService.addBankAccount(payload);

    app.$store.dispatch("snackbar/openSnackbar", {
      text: "Bank Account Saved",
    });
    return data;
  },

  //================ Update Bank Account =======//
  async updateBankAccount(context, payload) {
    let { data } = await BankService.updateBankAccount(payload);

    app.$store.dispatch("snackbar/openSnackbar", {
      text: "Bank Account Updated Successfully",
    });
    return data;
  },

  // =============== Fetch Notification ============//
  async getNotification({ commit }, payload) {
    let data = await UserService.fetchNotifications(payload)
    commit('saveNotifications', data.payload)
    return data
  }
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
