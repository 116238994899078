import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class ThriftflexService {
  // Get Thriftflex
  static async getThriftflex() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.THRIFTFLEX);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Get Frequency
  static async getFrequency() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.THRIFTFLEX + "/frequency");
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Start Thriftflex
  static async startThriftflex(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.THRIFTFLEX + "/start",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Pause Thriftflex
  static async pauseThriftflex(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.THRIFTFLEX + "/pause",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Restart Thriftflex
  static async restartThriftflex(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.THRIFTFLEX + "/restart",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }
}
