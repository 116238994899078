import Vue from "vue";
import Vuex from "vuex";

import snackbar from "./snackbar.module";
import auth from "@/store/modules/auth.module";
import user from "./modules/user.module";
import loan from "./modules/loan.module";
import thriftflex from "@/store/modules/thriftflex.module";
import powersave from "@/store/modules/powersave.module";
import projectsave from "@/store/modules/projectsave.module";
import supersave from "@/store/modules/supersave.module";
import cards from "@/store/modules/cards.module";
import wallet from "@/store/modules/wallet.module";
import payments from "@/store/modules/payments.module";
import reward from "./modules/rewards.module";
import history from "./modules/history.module";

Vue.use(Vuex);

const state = {
  userDetails: null,
  Thriftflex: null,
  Powersave: null,
  Projectsave: null,
  Supersave: null,
  cardDetails: null,
  walletSetup: null,
  isloggedIn: false,
};

const mutations = {
  storeUser(state, user) {
    state.userDetails = user;
  },
  removeUser(state) {
    state.userDetails = null;
  },
  setThriftflex(state, payload) {
    state.Thriftflex = payload;
  },
  setPowersave(state, payload) {
    state.Powersave = payload;
  },
  setProjectsave(state, payload) {
    state.Projectsave = payload;
  },
  setSupersave(state, payload) {
    state.Supersave = payload;
  },
  saveCards(state, payload) {
    state.cardDetails = payload;
  },
  setWalletsetup(state, payload) {
    state.walletSetup = payload;
  },
};

const actions = {
  getSavedWallet({ state }, payload) {
    const { customer } = state.userDetails;
    if (customer && customer.wallets) {
      return customer.wallets.find((wallet) => wallet.wallet_type === payload);
    }
  },
};

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    snackbar,
    auth,
    user,
    loan,
    thriftflex,
    powersave,
    projectsave,
    supersave,
    payments,
    cards,
    wallet,
    reward,
    history,
  },
});

export default store;
