import app from "@/main";
// import Vue from "vue";

function errorHandler(e) {
  let defaultData = "Unable to complete request";

  const statuses = function (data) {
    return {
      400: {
        data,
        action: () => {},
      },
      401: {
        data: document.location.pathname.includes("/login")
          ? data
          : "You are not logged in",
        action: () => {
          // if (document.location.pathname === "/") {
          //   app.$router.replace({
          //     name: "Login",
          //   });
          // } else if (!document.location.pathname.includes("/login")) {
          //   app.$router.replace({
          //     path: `/?next=${document.location.pathname}`,
          //   });
          // }

          app.$store.dispatch('auth/logout');
        },
      },
      403: {
        data,
        action: () => {},
      },
      404: {
        data: "Oops! an error occurred ",
        action: () => {},
      },
      500: {
        data: "Oops! an error occurred",
        action: () => {},
      },
    };
  };

  const mode = e.response && statuses(e.response.data)[e.response.status];
  if (mode) {
    mode.action();
  }

  const data = mode.data || defaultData;
  const status = e.response ? e.response.status : 0;
  return {
    status,
    data,
  };
}

function isUserLoggedIn() {
  try {
    const status = sessionStorage.getItem("authToken");
    return status != null;
  } catch (error) {
    return false;
  }
}

/**
 * Handles how error is displayed to the user
 * @param {Error} err object
 * @param {Boolean} throwAll
 * @param {Boolean} popToast
 */
function resolveRequestError(err, throwAll = true, popToast = true) {
  // app.$Progress.fail();
  if (err.message.includes('timeout')) {
    app.$store.dispatch("snackbar/openSnackbar", { text: 'Network Error' });
    if (throwAll) throw data;
  }

  const { status, data } = errorHandler(err);
  
  // if form validation errors are present, throw it so the caller can catch it
  if (data.data) throw data.data;

  let text = data.message || (data.error && data.error[0].message);

  if (!text) text = data;
  if (popToast) app.$store.dispatch("snackbar/openSnackbar", { text });

  if (status === 0 && !popToast)
    app.$store.dispatch("snackbar/openSnackbar", { text });

  if (throwAll) throw data;
}

export { resolveRequestError, isUserLoggedIn };
