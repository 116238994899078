import { CardService } from "@/services";

const actions = {
  // GET CARDS
  async getCards({ commit }) {
    const data = await CardService.getCard();
    await commit("saveCards", data.payload, {
      root: true,
    });
    return data;
  },

  // ADD CARDS
  async addCards() {
    const data = await CardService.addCard();
    return data;
  },

  // SET DEFAULT CARD
  async setDefaultCard(_, payload) {
    const data = await CardService.setDefaultCard(payload);
    return data;
  },

  // DELETE CARD
  async deleteCardAuth(_, id) {
    const data = await CardService.deleteCardAuth(id);
    return data;
  },
};

export default {
  namespaced: true,
  actions,
};
