import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class CardService {
  // Get Cards
  static async getCard() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.CARDAUTH);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  //ADD CARDS
  static async addCard() {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.ADD_CARD);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // SET DEFAULT CARD
  static async setDefaultCard(payload = {}) {
    try {
      const { data } = await Vue.http.patch(
        ENDPOINTS.SET_DEFAULT_CARD,
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // DELETE CARD
  static async deleteCardAuth(cardId) {
    try {
      const { data } = await Vue.http.delete(
        ENDPOINTS.DELETE_CARD(cardId),
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }
}
