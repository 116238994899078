import { SupersaveService } from "@/services";

const state = {};

const mutations = {};

const getters = {};

const actions = {
  async getSupersave({ commit }) {
    const data = await SupersaveService.getSupersave();
    await commit("setSupersave", data.payload, {
      root: true,
    });
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};
