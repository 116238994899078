import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";
import { extractQueryParams } from "@/utils/helpers"

export default class UserService {

  static async fetchSecurityQuestion() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.SETUP_SECURTY_QUESTION);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async fetchNotifications(payload) {
    try {
      const endpoint = extractQueryParams(ENDPOINTS.LOAN_NOTIFICATION, payload);
      const { data } = await Vue.http.get(endpoint);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async fetchBanner() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.DASHBOARD_BANNER);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async fetchAllBanners() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.DASHBOARD_BANNER_ALL);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Fetch purpose list for subscription
  static async fetchSavingsPurpose() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.FETCH_SAVINGS_PURPOSE);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async markAsRead(payload) {
    try {
      const { data } = await Vue.http.patch(ENDPOINTS.LOAN_NOTIFICATION, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async saveSecurityQuestion(payload) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.SECURTY_QUESTION, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }
}
