import Vue from "vue";
import VueRouter from "vue-router";
// import * as ROUTES from "./routes";

//modules routes
import authRoutes from "./route_modules/auth";
import dashboardRoutes from "./route_modules/dashboard";
import onboardingRoutes from "./route_modules/onboarding";
import rewardsRoutes from "./route_modules/rewardsRoutes";
import profileRoutes from "./route_modules/profile";
import portfolioRoutes from "./route_modules/portfolio";

import { isUserLoggedIn } from "../utils/auth";

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...portfolioRoutes,
  ...profileRoutes,
  ...rewardsRoutes,
  ...onboardingRoutes,
  ...dashboardRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const checkLoginStatus = async (checks) => {
  if (isUserLoggedIn()) {
    checks.next();
  } else {
    checks.next({
      path: "/",
      query: { redirect: checks.to.fullpath },
    });
  }
};

router.beforeEach((to, _from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    checkLoginStatus({ to, _from, next });
  } else if (!to.meta.requiresAuth && isUserLoggedIn()) {
    next({ path: "/dashboard" });
  } else {
    next();
  }
});

export default router;
