import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class BankService {
  // Fetch Bank Lists
  static async fetchBankLists() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.FETCH_BANK_LIST);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  //fetch user's bank account 
  static async fetchBankAcct() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.BANK_ACCOUNT);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Resolve account name
  static async resolveAccountName(payload) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.RESOLVE_ACCOUNT, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  //Add bank account 
  static async addBankAccount(payload) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.BANK_ACCOUNT, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  //Update bank account
  static async updateBankAccount(payload) {
    try {
      const { data } = await Vue.http.put(ENDPOINTS.BANK_ACCOUNT, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }
}
