import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import { router } from "./http";
import store from "./store";
import "./main-includes";

import "@/assets/styles/imports.scss";
import vuetify from './plugins/vuetify';

Vue.http.defaults.headers.common[
	'Authorization'
] = `Bearer ${sessionStorage.getItem('authToken')}`

let app;

app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");

export default app;
