import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class WithdrawalService {
  // Get Token
  static async withdrawalToken() {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.WITHDRAWAL_TOKEN);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // withdraw
  static async withdrawalRequest(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.WITHDRAWAL_REQUEST,
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }
}
