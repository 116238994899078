import * as ROUTES from "../routes";

const routes = [
  {
    path: ROUTES.DASHBOARD,
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/dashboard"),
    meta: {
      title: "Dashboard",
      layout: "private",
      requiresAuth: true,
    },
  },
];

export default routes;
