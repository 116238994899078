<template>
  <div
    class="d-flex justify-space-between align-center"
  >
    <div class="">
      <v-img
        width="27"
        class="mb-1 align-self-start"
        src="@/assets/images/svg/back-arrow.svg"
      ></v-img>
    </div>

    <h2>{{ title }}</h2>

    <div class="">
      <v-img
        width="27"
        class="mb-1 align-self-start"
        src="@/assets/images/svg/info-icon.svg"
      ></v-img>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
  mounted() {
    console.log(this.$route);
  },
};
</script>
