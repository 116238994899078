import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class WalletService {
  // Get Cards
  static async getWalletsetup() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.WALLETSETUP);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Get Interest
  static async getInterestDetails() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.INTEREST);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async topupWallet(payload) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.WALLET_TOPUP, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }
}
