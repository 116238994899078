<template>
  <div>
    <small v-if="labelOutside" for="">{{ labelOutside }}</small>
    <v-text-field
      @input="$emit('input', $event)"
      class="rounded-0 mb-4"
      :label="labelInside"
      outlined
      v-bind="$attrs"
      :placeholder="placeholder"
      :type="type2"
      :value="value"
      hide-details="auto"
    >
      <template v-if="password" v-slot:append>
        <v-icon @click="visibleController" color="#000">
          {{ passwordIcon }}
        </v-icon>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  inheritAttrs: true,
  data: () => ({
    passwordIcon: "mdi-eye-off",
    show: false,
    yyy: "",
    PasswordType: "password",
  }),
  props: {
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    labelInside: {
      type: String,
    },
    labelOutside: {
      type: String,
    },
    password: {
      type: Boolean,
    },
    value: {
      type: [String, Number],
      required: false,
      default: "",
    },
  },
  computed: {
    type2() {
      if (this.password) {
        return this.PasswordType;
      } else {
        return this.type;
      }
    },
  },
  methods: {
    visibleController() {
      if (this.show == false) {
        this.passwordIcon = "mdi-eye";
        this.PasswordType = "text";
        this.show = true;
      } else {
        this.passwordIcon = "mdi-eye-off";
        this.PasswordType = "password";
        this.show = false;
      }
    },
  },
};
</script>

<style lang="scss">
.v-text-field--outlined fieldset {
  border: 1px solid #000000 !important;
}
</style>
