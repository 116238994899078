<template>
  <div
    class="sidebar d-flex flex-column"
    :class="{
      open: openMobileSidebar,
      close: !openMobileSidebar,
      'dashboard-sidebar': $route.path == '/dashboard'
    }"
  >
    <div class="logo-brand mb-12 px-5">
      <v-img src="@/assets/images/png/fajo-logo-2.png" width="97" height="44">
        <v-icon @click="closeSideBar" color="dark darken-2" class="close-icon"> mdi-close </v-icon>
      </v-img>
    </div>
    <div
      class="links d-flex align-center mb-5 px-5 py-2 pointer"
      v-for="(link, index) in links"
      :key="index"
      :class="{ active: active == index }"
      @click="moveToNextPage(link, index)"
    >
      <div class="mr-4">
        <v-img
          :src="active == index ? link.active_icon : link.icon"
          class="icon"
        ></v-img>
      </div>

      <p class="mb-0 font-weight-midium dark--text text-capitalize">
        {{ link.text }}
      </p>
    </div>

    <div
      @click="logout"
      class="links pointer d-flex align-center logout mt-auto px-5"
    >
      <div class="mr-4">
        <v-img src="@/assets/images/png/logout.png" width="22"></v-img>
      </div>
      <p class="mb-0 pa-3 f text-capitalize">logout</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      links: [
        {
          icon: require("@/assets/images/png/dashboard.png"),
          text: "dashboard",
          active_icon: require("@/assets/images/png/dashboard-active.png"),
        },
        {
          icon: require("@/assets/images/png/portfolio.png"),
          text: "portfolio",
          active_icon: require("@/assets/images/png/portfolio-active.png"),
        },
        {
          icon: require("@/assets/images/png/rewards.png"),
          text: "rewards",
          active_icon: require("@/assets/images/png/rewards-active.png"),
        },
        {
          icon: require("@/assets/images/png/user.png"),
          text: "profile",
          active_icon: require("@/assets/images/png/user-active.png"),
        },
      ],
    };
  },

  props: {
    openMobileSidebar: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    $route() {
      let routePath = this.$route.path.split("/");

      let routeIdx = this.links.findIndex(
        (elm) => elm.text == routePath[1].toLowerCase()
      );
      this.active = routeIdx;
    },
  },

  mounted() {
    let routePath = this.$route.path.split("/");

    let routeIdx = this.links.findIndex(
      (elm) => elm.text == routePath[1].toLowerCase()
    );
    this.active = routeIdx;
  },
  methods: {
    moveToNextPage(link, index) {
      this.active = index;
      this.$router.push('/' + link.text).catch(() => {});
    },
    
    closeSideBar() {
      this.$emit('closeMobileSidebar')
    },

    logout() {
      sessionStorage.clear("authToken");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.open {
  left: 0 !important;
  transition: 0.5s ease-in-out;
}

.dashboard-sidebar {
  background: #FFF6F2 !important;
}

.sidebar {
  height: 100vh;
  width: 300px;
  background: #fff;
  position: fixed;
  padding: 35px 0;
  z-index: 100;

  @media screen and (max-width: 768px) {
    left: -300px;
    width: 100%;

    &.close {
      left: -100% !important;
      transition: 0.5s ease-in-out;
    }
  }

  .links {
    .icon {
      width: 22px;
    }
  }

  .logout {
    &:hover {
      background: rgba(199, 6, 6, 0.1);
      color: #c72a06;
      transition: 0.5s;
    }
  }

  p {
    font-size: 19px;
    font-weight: 500;
  }
  a {
    text-decoration: none;
  }

  .active {
    border-left: 4px solid var(--primary);
  }

  .close-icon {
    transform: translateX(77vw);
    position: fixed;
    margin-top: 0.5rem;
    display: none;

     @media screen and (max-width: 768px) {
      display: block;
    }
  }
}
</style>
