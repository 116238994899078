import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class LoanService {
  static async fetchLoanStatus() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.LOAN_STATUS);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async loanInformation() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.LOAN_REQUEST);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async generateOverview(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.GENERATE_LOAN_OVERVIEW,
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async requestLoan(payload) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.LOAN_REQUEST, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async verifySurety(payload) {
    try {
      const data = await Vue.http.get(ENDPOINTS.VERIFY_SURETY(payload));
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async suretyApproval(payload) {
    try {
      const { data } = await Vue.http.patch(
        ENDPOINTS.LOAN_SURETY_APPROVAL(payload)
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async suretyDisapproval(payload) {
    try {
      const { data } = await Vue.http.patch(
        ENDPOINTS.LOAN_SURETY_DISAPPROVAL(payload)
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async makeLoanPayment(payload) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.LOAN_PAYMENT, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }
}
