import app from "@/main";
import { AuthService } from "@/services";

const actions = {
  // LOGIN
  async login(context, { username, password }) {
    const { payload } = await AuthService.login({ username, password });
    await AuthService.authenticateUser(payload.access_token);
    await app.$store.dispatch("user/fetchUser");
    app.$store.dispatch("snackbar/openSnackbar", {
      text: "Login successful",
    });

    app.$router.push("/dashboard");
  },

  // REGISTER
  async register(
    context,
    { first_name, last_name, email, phone_no, pin, referral_code, confirm_pin }
  ) {
    const data = await AuthService.register({
      first_name,
      last_name,
      email,
      phone_no,
      referral_code,
      pin,
      confirm_pin,
    });
    let message = "Registration successful, please verify your account";
    app.$store.dispatch("snackbar/openSnackbar", { text: message });
    localStorage.setItem("RegisteredUser", JSON.stringify(data.payload));
    app.$router.push({ name: "Verify Account" });
  },

  // VERIFY ACCOUNT
  async verifyAccount(context, { otp, auth_id }) {
    await AuthService.verifyAccount({
      otp,
      auth_id,
    });
    localStorage.removeItem("RegisteredUser");
  },

  // Forgot Password
  async forgotPassword(context, payload) {
    const data = await AuthService.forgotPassword(payload);
    return data;
  },

  // RESET PIN
  async resetPassword(context, credentials) {
    const data = await AuthService.resetPassword(credentials);
    return data;
  },

  // Change password
  async changePassword(context, credentials) {
    const data = await AuthService.changePassword(credentials);

    app.$store.dispatch("snackbar/openSnackbar", {
      text: "Password Changed  Successfully",
    });
    return data;
  },

  // Change phone number
  async changePhoneNumber(context, payload) {
    const { data } = await AuthService.changePhoneNumber(payload);
    let user = JSON.parse(localStorage.getItem("RegisteredUser"));
    user.auth = data.payload;
    localStorage.setItem("RegisteredUser", JSON.stringify(user));
    return data;
  },

  //Logout
  async logout() {
    sessionStorage.removeItem("authToken");
    // window.location.reload();
    if (app.$route.name !== 'Login') {
      app.$router.push("/login").catch();
    }
  },
};

export default {
  namespaced: true,
  actions,
};
