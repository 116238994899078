<template>
  <div :class="{ 'dashboard-layout': $route.path == '/dashboard' }" class="private-layout">
    <div class="toggle-bar" @click="sidebar = !sidebar">
      <v-icon color="dark darken-2"> mdi-menu </v-icon>
    </div>

    <fj-sidebar :openMobileSidebar="sidebar" @closeMobileSidebar="sidebar = !sidebar" />
    <div class="mb-14 pt-5 page-container">
      <slot />
    </div>
  </div>
</template>
<script>
import FjSidebar from "../components/FjSidebar";
export default {
  data() {
    return {
      sidebar: false,
    };
  },

  watch: {
    $route() {
      this.sidebar = false;
    },
  },
  components: {
    FjSidebar,
  },
};
</script>

<style lang="scss" scoped>
.private-layout {
  min-height: 100vh;
  overflow: auto;
  background: rgba(247, 81, 22, 0.1);

  .app-nav {
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 100%;
  }

  .page-container {
    width: calc(100% - 300px);
    margin-left: auto;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .toggle-bar {
    position: absolute;
    right: 13px;
    top: 30px;
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }
}

.dashboard-layout {
  background: #ffff !important;
}
</style>
