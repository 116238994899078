import { ThriftflexService } from "@/services";
import app from "@/main";

const state = {
  initiatingThriftflex: null,
};

const mutations = {
  initiateThriftflex(state, payload) {
    state.initiatingThriftflex = payload;
  },
};

const getters = {
  thriftflexInitializers: (state) => state.initiatingThriftflex,
};

const actions = {
  async getThriftflex({ commit }) {
    const data = await ThriftflexService.getThriftflex();
    await commit("setThriftflex", data.payload, {
      root: true,
    });
  },

  async saveInitiateThriftflex({ commit }, payload) {
    commit("initiateThriftflex", payload);
  },

  async getFrequency() {
    const data = await ThriftflexService.getFrequency();
    return data.payload;
  },

  async startThriftflex(context, details) {
    const data = await ThriftflexService.startThriftflex(details);
    return data;
  },

  async pauseThriftflex(context, details) {
    const data = await ThriftflexService.pauseThriftflex(details);
    await context.dispatch("getThriftflex");
    app.$store.dispatch("snackbar/openSnackbar", {
      text: "Thriftflex subscription has been Paused",
    });
    return data;
  },

  async restartThriftflex(context, details) {
    const data = await ThriftflexService.restartThriftflex(details);
    await context.dispatch("getThriftflex");
    app.$store.dispatch("snackbar/openSnackbar", {
      text: "Thriftflex subscription has been activated",
    });
    return data;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};
