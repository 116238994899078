import { HistoryService } from "@/services";
const state = {
  moduleName: null,
  loanPaymentHistories: null,
};
const mutations = {
  saveModuleName(state, credential) {
    state.moduleName = credential;
  },

  saveLoanPaymenntHistory(state, credentials) {
    state.loanPaymentHistories = credentials;
  },
};
const actions = {
  saveModule({ commit }, payload) {
    commit("saveModuleName", payload);
  },

  // Fetching specific transactions
  async getTransactions(context, payload) {
    const data = await HistoryService.fetchTransactions(payload);
    return data;
  },

  // Fetch withdrawl requests
  async getWithdrawalHistory(context, params) {
    const data = await HistoryService.fetchWithdrawalHistory(params);
    return data;
  },

  // Get loan history
  async getLoanHistory(context, params) {
    const { payload } = await HistoryService.fetchLoanHistory(params);
    return payload;
  },

  // Get interest history
  async fetchInterestHistory(context, payload) {
    const data = await HistoryService.fetchInterestHistory(payload);
    return data;
  },

  // Fetch Activities
  async getActivities() {
    const data = await HistoryService.fetchActivities();
    return data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
