// import App from '@/main'
import * as ROUTES from "../routes";
import store from "../../store/index";

const routes = [
  {
    path: ROUTES.REWARDS,
    name: "Rewards",
    component: () => import(/* webpackChunkName: "about" */ "@/views/rewards"),
    meta: {
      title: "Rewards",
      layout: "private",
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const bankAdded = localStorage.getItem("bank");
      if (bankAdded == "false") {
        store.dispatch("snackbar/openSnackbar", {
          text: "Please add your bank details",
        });
        next("/profile/bank-account");
      } else {
        next();
      }
    },
  },
  {
    path: ROUTES.REWARDS_WITHDRAWAL,
    name: "Rewards Withdrawal",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/rewards/components/Withdrawal"
      ),
    meta: {
      title: "Rewards",
      layout: "private",
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const bankAdded = localStorage.getItem("bank");
      if (bankAdded == "false") {
        store.dispatch("snackbar/openSnackbar", {
          text: "Please add your bank details",
        });
        next("/profile/bank-account");
      } else {
        next();
      }
    },
  },
];

export default routes;
