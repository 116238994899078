<template>
  <div
    class="product pa-5"
    :class="color"
    :style="{ height: '200px' }"
  >
    <small class="white--text d-block">{{ text }} </small>
    <small class="white--text d-block sub">{{ subtitle }}</small>
    <v-img :src="icon" class="card-icon"></v-img>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    text: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    color: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  height: 29.2%;
  width: 100%;
  position: relative;

  @media screen and (max-width: 768px) {
    height: 220px !important;
  }

  small {
    width: 90%;
    font-size: 14px;

    &.sub {
      font-size: 13px;
    }
  }
  .card-icon {
    width: 100px;
    position: absolute;
    bottom: 0;
    right: 30px;
  }
}
</style>
