<template>
  <div>
    <label for="">{{ label }}</label>
    <v-otp-input
      :length="length"
      auto-focus
      height="80px"
      v-model="formValue"
      @input="$emit('input', $event)"
      color="primary"
      :type="type"
    ></v-otp-input>
  </div>
</template>

<script>
export default {

  data(){
    return{
      formValue: ''
    }
  },
  props: {
    length: {
      type: String,
      default: "4",
    },
    label: {
      type: String,
    },
    value:{
      type: String
    },
    type:{
      type : String,
      default : 'text'
    }
  },

  watch: {
    formValue(v) {
      this.$emit("input", v);
      this.formValue = v;
    },
    value(v) {
      this.formValue = v;
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-otp-input .v-input .v-input__control .v-input__slot {
  background: transparent !important;
}
</style>
