import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class AuthService {
  // LOGIN USER
  static async login({ username, password }) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.LOGIN, {
        password,
        username,
      });
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // REGISTER USER
  static async register({
    first_name,
    last_name,
    email,
    phone_no,
    pin,
    confirm_pin,
    referral_code,
  }) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.REGISTER, {
        first_name,
        last_name,
        email,
        phone_no,
        referral_code,
        pin,
        confirm_pin,
      });
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  // VERIFY ACCOUNT
  static async verifyAccount({ otp, auth_id }) {
    try {
      const { data } = await Vue.http.get(
        ENDPOINTS.VERIFY_ACCOUNT + `/${auth_id}` + `/${otp}`
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Resend OTP
  static async resendOtp(payload) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.RESENDOTP, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Forgot Password
  static async forgotPassword(payload) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.FORGOT_PASSWORD, payload);
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  // Reset PIN
  static async resetPassword(payload) {
    try {
      const data = await Vue.http.post(ENDPOINTS.RESET_PASSWORD, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // CHANGE PASSWORD
  static async changePassword(payload) {
    try {
      const data = await Vue.http.patch(ENDPOINTS.CHANGE_PASSWORD, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // CHANGE PHONE NUMBER
  static async changePhoneNumber(payload) {
    try {
      const data = await Vue.http.patch(ENDPOINTS.CHANGE_PHONE_NUMBER, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // VERIFY REFERAL CODE
  static async verifyReferral(payload) {
    try {
      const data = await Vue.http.get(ENDPOINTS.VERIFY_REFERRAL(payload));
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // FETCH USER'S PROFILE
  static async fetchUserProfile() {
    try {
      const data = await Vue.http.get(ENDPOINTS.USER_PROFILE);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // FETCH USER'S PROFILE
  static async updateProfile(payload) {
    try {
      const data = await Vue.http.put(ENDPOINTS.USER_PROFILE, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // AUTHENTICATE USER
  static authenticateUser(token) {
    Vue.http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    sessionStorage.setItem("authToken", token);
  }
}
