import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";
import { extractQueryParams } from "@/utils/helpers"

export default class HistoryService {
  static async fetchTransactions(payload) {
    try {
      const url = extractQueryParams(ENDPOINTS.TXTN, payload)
      const { data } = await Vue.http.get(url);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async fetchActivities() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.FETCH_ACTIVIVTIES);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async fetchLoanTransactions() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.FETCH_LOAN_TRANSACTION);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async fetchWithdrawalHistory(params) {
    try {
      const url = extractQueryParams(ENDPOINTS.WITHDRAWAL_REQUEST, params);
      const { data } = await Vue.http.get(url);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async fetchInterestHistory(params) {
    try {
      const url = extractQueryParams(ENDPOINTS.WALLET_INTEREST, params);
      const { data } = await Vue.http.get(url);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async fetchLoanHistory(params) {
    try {
      const url = extractQueryParams(ENDPOINTS.LOAN_REQUEST, params);
      const { data } = await Vue.http.get(url);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }
}
