<template>
  <v-app>
    <v-main>
      <component :is="layout">
        <router-view v-if="!loading" />
      </component>

      <fj-snackbar />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
// import { isUserLoggedIn } from "./utils/auth";
export default {
  name: "App",

  mounted() {
    const token = sessionStorage.getItem("authToken");
    if (token) {
      this.updateUser();
    }
  },

  components: {
    FjSnackbar: () => import("./components/FjSnackbar"),
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    ...mapActions("user", ["fetchUser"]),
    async updateUser() {
      try {
        this.loading = true;
        await this.fetchUser();
        this.loading = false;
      } catch (error) {
        sessionStorage.removeItem("authToken");
        this.$router.push("/login");
      }
    },
  },

  computed: {
    layout() {
      return this.$route.meta.layout || "public";
    },
  },
};
</script>
