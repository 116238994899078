import { ProjectsaveService } from "@/services";
import app from "@/main";

const state = {
  initiatingProjectsave: null,
  initiatingTransfer: null,
  initiatingWithdrawal: null,
};

const mutations = {
  initiateProjectsave(state, payload) {
    state.initiatingProjectsave = payload;
  },
  initiateTransfer(state, payload) {
    state.initiatingTransfer = payload;
  },
  initiateWithdrawal(state, payload) {
    state.initiatingWithdrawal = payload;
  },
};

const getters = {
  projectsaveInitializers: (state) => state.initiatingProjectsave,
  transferInitializers: (state) => state.initiatingTransfer,
  withdrawalInitializers: (state) => state.initiatingWithdrawal,
};

const actions = {
  async getProjectsave({ commit }) {
    const data = await ProjectsaveService.getProjectsave();
    await commit("setProjectsave", data.payload, {
      root: true,
    });
  },

  async startProjectsave(context, details) {
    const data = await ProjectsaveService.startProjectsave(details);
    return data;
  },

  async saveInitiateProjectsave({ commit }, payload) {
    commit("initiateProjectsave", payload);
  },

  async saveInitiateTransfer({ commit }, payload) {
    commit("initiateTransfer", payload);
  },

  async saveInitiateWithdrawal({ commit }, payload) {
    commit("initiateWithdrawal", payload);
  },

  async getFrequency() {
    const data = await ProjectsaveService.getFrequency();
    return data.payload;
  },

  async pauseProjectsave(context, details) {
    const data = await ProjectsaveService.pauseProjectsave(details);
    await context.dispatch("getProjectsave");
    app.$store.dispatch("snackbar/openSnackbar", {
      text: "Project Save subscription has been Paused",
    });
    return data;
  },

  async restartProjectsave(context, details) {
    const data = await ProjectsaveService.restartProjectsave(details);
    await context.dispatch("getProjectsave");
    app.$store.dispatch("snackbar/openSnackbar", {
      text: "Project Save subscription has been activated",
    });
    return data;
  },

  async makeTransfer(context, payload) {
    const data = await ProjectsaveService.makeTransfer(payload);
    return data
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};
