const AUTH = "/auth";
const LOAN = "/loan";
const CUSTOMER = "/customer";
const THRIFTFLEXROUTE = "/thriftflex";
const CARD = "/cardauth";
const CARDPAYMENTS = "/payment";
const POWERSAVEROUTE = "/powersave";
const PROJECTSAVEROUTE = "/projectsave";
const SUPERSAVEROUTE = "/supersave";
const COMMISSION = "/commission";
const SUBSCRIPTIONROUTE = "/subscription";

// ============  AUTHENTICATION ENDPOINT =============
export const LOGIN = `${AUTH}/login`;
export const REGISTER = `${AUTH}/customer-signup`;
export const VERIFY_ACCOUNT = `${AUTH}/verify-account`;
export const FORGOT_PASSWORD = `${AUTH}/forgot-password`;
export const RESET_PASSWORD = `${AUTH}/reset-password`;
export const VERIFY_REFERRAL = (phone_no) =>
  `${AUTH}/validate-referral-code?referral_code=${phone_no} `;
export const RESENDOTP = `${AUTH}/resend-otp`;
export const CHANGE_PASSWORD = `${AUTH}/password`;
export const CHANGE_PHONE_NUMBER = `${AUTH}/phone-number`;

// =============== USER PROFILE ===============///
export const USER_PROFILE = `${CUSTOMER}/profile`;
export const DASHBOARD_BANNER = `${CUSTOMER}/dashboard/banner`;
export const DASHBOARD_BANNER_ALL = `${CUSTOMER}/dashboard/banner/all`;

// ===============  BANK ACCOUNT ===============///
export const FETCH_BANK_LIST = `/bank-account/list-all-banks`;
export const RESOLVE_ACCOUNT = `/bank-account/resolve`;
export const BANK_ACCOUNT = "/bank-account";

// =============== SUBSCRIPTION ENDPOINT ===============
export const FETCH_SAVINGS_PURPOSE = `${SUBSCRIPTIONROUTE}/purpose-list`;

// =============== THRIFT FLEX ENDPOINT ===============
export const THRIFTFLEX = `${THRIFTFLEXROUTE}`;

// ================= CARD SERVICE ==============
export const ADD_CARD = `${CARD}`;
// =============== POWERSAVE ENDPOINT ===============
export const POWERSAVE = `${POWERSAVEROUTE}`;

// =============== PROJECTSAVE ENDPOINT ===============
export const PROJECTSAVE = `${PROJECTSAVEROUTE}`;

// =============== SUPERSAVE ENDPOINT ===============
export const SUPERSAVE = `${SUPERSAVEROUTE}`;

// ================= CARD ENDPOINT ==============
export const CARDAUTH = `${CARD}/customer`;
export const SET_DEFAULT_CARD = `${CARD}/default`;
export const DELETE_CARD = (id) => `${CARD}/${id}`;

// ========== PAYMENT ENDPOINT =============
export const PAYMENT = `${CARDPAYMENTS}`;

// ============ WALLET ENDPOINT ===========
export const WALLETSETUP = `/wallet-setup`;
export const INTEREST = "interest";
export const WALLET_INTEREST = `/wallet-interest`;
export const WALLET_TOPUP = '/wallet/topup';

// =========== SECURITY ==================//
export const SETUP_SECURTY_QUESTION = `/setup-security-question`;
export const SECURTY_QUESTION = `${CUSTOMER}/security-question`;

// ============= notification============ //
export const LOAN_NOTIFICATION = `${CUSTOMER}/notification`;

// ============= REWARDS =================== //
export const FETCH_COMMISSION = `${CUSTOMER}/rewards`;
export const REQUEST_TOKEN = `${COMMISSION}/token`;
export const FETCH_ACTIVE_REFERRALS = `${CUSTOMER}/downline/active`;
export const FETCH_INACTIVE_REFERRALS = `${CUSTOMER}/downline/inactive`;

export const WITHDRAWAL_FUNDS = `${COMMISSION}/withdraw`;
export const FETCH_ACTIVIVTIES = `${CUSTOMER}/activity`;

//============= LOAN================//
export const LOAN_STATUS = `${LOAN}/status`;
export const LOAN_REQUEST = `${LOAN}`;
export const VERIFY_SURETY = (phone_no) => `${LOAN}/verify-surety/${phone_no}`;
export const GENERATE_LOAN_OVERVIEW = `${LOAN}/details`;
export const LOAN_SURETY_APPROVAL = (id) => `${LOAN}/surety/approve/${id}`;
export const LOAN_SURETY_DISAPPROVAL = (id) =>
  `${LOAN}/surety/disapprove/${id}`;
export const LOAN_PAYMENT = `${LOAN}/payment`;

// =========== withdrawal =============== //
export const WITHDRAWAL_REQUEST = `withdrawal-request`;
export const WITHDRAWAL_TOKEN = "withdrawal-request/token";
export const FETCH_WITHDRAWAL_REQUEST = (param) =>
  `withdrawal-request?wallet_id=${param.id}&page=${param.page} &limit=20`;
//==============TRANSACTIONS ===============//
export const TXTN = "/txtn";
export const FETCH_LOAN_TRANSACTION = `txtn?transaction_type=loan_payment`;
