import * as ROUTES from "../routes";

const routes = [
  {
    path: ROUTES.ONBOARDING,
    name: "Onboarding",
    redirect: ROUTES.LOGIN,
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/onboarding"),

    meta: {
      title: "Onboarding",
      layout: "public",
    },
  },
];

export default routes;
