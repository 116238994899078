import { RewardsService } from "@/services";
// import app from "@/main";

const state = {
  commission: null,
  active_referrals: null,
  inactive_referrals: null,
  initiatingWithdrawal: null,
};

const mutations = {
  saveCommission(state, payload) {
    state.commission = payload;
  },

  saveActiveReferrals(state, payload) {
    state.active_referrals = payload;
  },

  saveInactiveReferrals(state, payload) {
    state.inactive_referrals = payload;
  },

  saveWithdrawalDetails(state, payload) {
    state.initiatingWithdrawal = payload;
  },
};

const actions = {
  async fetchCommission({ commit }) {
    const data = await RewardsService.fetchCommission();
    commit("saveCommission", data.payload);
  },

  async fetchActiveReferrals({ commit }) {
    const data = await RewardsService.fetchActiveReferrals();
    commit("saveActiveReferrals", data.payload);
  },

  async fetchInactiveReferrals({ commit }) {
    const data = await RewardsService.fetchInactiveReferrals();
    commit("saveInactiveReferrals", data.payload);
  },

  async requestToken({ commit }, payload) {
    const data = await RewardsService.requestToken(payload);
    await commit("saveWithdrawalDetails", payload);
    return data;
  },

  async withdrawFunds(context, payload) {
    const data = await RewardsService.withdrawFunds(payload);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
