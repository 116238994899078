import { WalletService } from "@/services";

const state = {
  walletDetails: {},
};

const getters = {
  walletDetails: (state) => state.walletDetails,
};

const actions = {
  // GET WALLET SETUPS
  async getWalletsetup({ commit }) {
    const data = await WalletService.getWalletsetup();
    await commit("setWalletsetup", data.payload, {
      root: true,
    });
  },

  // GET INTEREST
  async getInterestDetails() {
    const data = await WalletService.getInterestDetails();
    return data;
  },

  async setWalletDetails({ state, dispatch }, walletType) {
    state.walletDetails = await dispatch("getSavedWallet", walletType, {
      root: true
    })
  },

  async topupWallet(context, payload) {
    const data = await WalletService.topupWallet(payload);
    return data
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
};
