import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class ProjectsaveService {
  // Get Project Save
  static async getProjectsave() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.PROJECTSAVE);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Get Frequency
  static async getFrequency() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.PROJECTSAVE + "/frequency");
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Start Project Save
  static async startProjectsave(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.PROJECTSAVE + "/start",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Pause Project Save
  static async pauseProjectsave(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.PROJECTSAVE + "/pause",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Restart Project Save
  static async restartProjectsave(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.PROJECTSAVE + "/restart",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Get Token
  static async sendToken(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.PROJECTSAVE + "/token",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  //withdraw
  static async withdrawalRequest(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.PROJECTSAVE + "/withdraw",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Transfer
  static async makeTransfer(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.PROJECTSAVE + "/transfer",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }
}
