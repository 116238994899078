import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class PowersaveService {
  // Get Power Save
  static async getPowersave() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.POWERSAVE);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Get Frequency
  static async getFrequency() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.POWERSAVE + "/frequency");
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Start Power Save
  static async startPowersave(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.POWERSAVE + "/start",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Pause Power Save
  static async pausePowersave(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.POWERSAVE + "/pause",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Restart Power Save
  static async restartPowersave(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.POWERSAVE + "/restart",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  // Get Token
  static async sendToken(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.POWERSAVE + "/token",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  //withdraw
  static async withdrawalRequest(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.POWERSAVE + "/withdraw",
        payload
      );
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }
}
