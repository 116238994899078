<template>
  <div class="d-flex justify-center w-100">
    <div
      class="a-card text-center"
      v-for="(item, index) in ButtonData"
      :key="index"
      @click="$emit('routePage')"
    >
      <div class="card d-flex align-center justify-center">
        <img :src="item.icon" />
      </div>
      <p>{{ item.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ButtonData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.a-card {
  width: 75px;
  height: 75px;
  .card {
    width: 100%;
    height: 100%;
    background: #fee5dc;
  }
  &:not(:last-child) {
    margin-right: 15px;
  }

  p {
    font-size: 12px;
  }
}
</style>
