import axios from "axios";
import router from "./router";

export const http = {
  install(Vue) {
    Vue.prototype.$http = Vue.http = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 120000,
    });
  },
};

export { router };

