import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class PaymentService {

    // VERIFY PAYMENT 
    static async verifyPayment({reference}) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.PAYMENT + `/verify/${reference}`)
            return data;
        } catch (error) {
            resolveRequestError(error)
        }
    }
}