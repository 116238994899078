// import app from "@/main";

/**
 * Captilize first letter of a string
 *
 * @param {String} str
 */
export const capitalizeFirstLetter = (str) => {
  if (str === null) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatDate = (date) => {
  let d = new Date(date);
  return d.toLocaleString(["en-Us"], {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
};

// export const formatTime = (timestamp) => {
//   if (!timestamp) return;
//   const date = timestamp.seconds
//     ? new Date(timestamp.seconds * 1000)
//     : timestamp;
//   return `${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`;
// };

export const formatAsMoney = (amount) => {
  return new Intl.NumberFormat().format(amount);
};

export const extractQueryParams = (base, query) => {
  if (typeof(query) === "object") {
    base = base + '?';
    for (const key in query) {
      if (base[base.length - 1] == '?') {
        base = base + `${key}=${query[key]}`
      } else {
        base = base + `&${key}=${query[key]}`
      }
     
    }
  }

  return base;
}
