<template>
  <v-btn
    :color="color"
    v-on="$listeners"
    :disabled="disabled"
    x-large
    :loading="loading"
    depressed
    tile
    :block="block"
    :outlined="outlined"
    class="text-capitalize"
  >
    <slot />
    <v-icon  small dense color="white" class="ml-2"  v-if="hasIcon"> mdi-arrow-right </v-icon>
  </v-btn>
</template>

<script>
export default {
  data: () => ({}),
  props: {
    color: {
      type: String,
      default: "primary",
    },
    outlined: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    block: {
      type: Boolean,
      default: false
    },
    hasIcon:{
      type: Boolean
    }
  },
};
</script>

<style lang="scss">
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  opacity: 0.5;
  background-color: #c4c4c4 !important;
  color: #ffffff !important;
}
</style>
