<template>
  <div class="d-flex flex-column justify-center align-center h-100 px-6">
    <div class="d-flex flex-column justify-center align-center">
      <v-img
        height="70"
        width="70"
        class="mb-10"
        src="@/assets/images/svg/checked.svg"
        v-if="status == 'success'"
      ></v-img>
      <v-img
        height="70"
        width="70"
        class="mb-10"
        src="@/assets/images/svg/failed.svg"
        v-if="status == 'failed'"
      ></v-img>
      <h4 class="mb-1">{{ title }}</h4>
      <small class="d-block text-center">{{ message }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
    },
    status: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
h4{
  font-size: 19px;
}
small{
  font-size: 14px;
  font-weight: 600;
}
</style>
