import Vue from "vue";
import { resolveRequestError } from "@/utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class RewardsService {
  static async fetchCommission() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.FETCH_COMMISSION);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async fetchActiveReferrals() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.FETCH_ACTIVE_REFERRALS);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async fetchInactiveReferrals() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.FETCH_INACTIVE_REFERRALS);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async requestToken(payload) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.REQUEST_TOKEN, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }

  static async withdrawFunds(payload) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.WITHDRAWAL_FUNDS, payload);
      return data;
    } catch (error) {
      resolveRequestError(error);
    }
  }
}
