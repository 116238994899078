import { LoanService } from "@/services";
const state = {
  loanStatus: null,
  loanOverview: null,
};

const mutations = {
  SAVE_LOAN_STATUS(state, payload) {
    state.loanStatus = payload;
  },
  SAVE_LOAN_OVERVIEW(state, payload) {
    state.loanOverview = payload;
  },
};
const actions = {
  async getLoanStatus({ commit }) {
    let data = await LoanService.fetchLoanStatus();

    commit("SAVE_LOAN_STATUS", data.payload);
  },

  async getLoanSurety(context, payload) {
    let { data } = await LoanService.verifySurety(payload);
    // commit("SAVELOANSTATUS", data.payload);

    return data;
  },

  async loanInformation() {
    let data = await LoanService.loanInformation();
    return data;
  },

  async generateOverview({ commit }, payload) {
    let data = await LoanService.generateOverview(payload);
    commit("SAVE_LOAN_OVERVIEW", data.payload);
  },

  async requestLoan(context, payload) {
    let data = await LoanService.requestLoan(payload);
    return data;
  },

  async suretyApproval(context, payload) {
    let data = await LoanService.suretyApproval(payload);
    return data;
  },

  async suretyDisapproval(context, payload) {
    let data = await LoanService.suretyDisapproval(payload);
    return data;
  },

  async initiateLoanPayment(context, payload) {
    let data = await LoanService.makeLoanPayment(payload);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
